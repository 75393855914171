import React, { FC, memo } from 'react';
import Button from 'components/Button';
import {
  Container,
  Content,
  Wrapper,
  TitleContact,
  Title,
  Link,
  Anchor,
  Section,
  Row,
  IconMail,
  IconPhone,
  IconClock,
  Logo,
  Text,
  IconGroup,
  IconFacebook,
  IconInstagram,
  IconLinkedin,
  Line,
  LegalSection,
  LegalLink,

  LastLinks,
  SocialLink,
} from './styles';
import useConnect from './connect';

const Footer: FC = () => {
  const { isLoading, spaceCategories, locations } = useConnect();

  if (isLoading) return null;

  return (
    <Container>
      <Content>
        <Wrapper>
          <Section>
            <Logo />
            <Text>Sedee. Selección de Espacios para Eventos S.L.</Text>
            <Text>C. Almutamid, 6, Edificio Vega del Rey, Local 101</Text>
            <Text>Camas (Sevilla), 41910</Text>
            <IconGroup>
              <SocialLink href="https://www.instagram.com/sedee_spain?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                <IconInstagram />
              </SocialLink>
              <SocialLink href="https://www.facebook.com/profile.php?id=100088855201757">
                <IconFacebook />
              </SocialLink>
              <SocialLink href="https://es.linkedin.com/company/sedee">
                <IconLinkedin />
              </SocialLink>
            </IconGroup>
            <TitleContact>Contactar</TitleContact>
            <Row>
              <IconMail />
              <Anchor href="mailto:ayuda@sedee.com">info@sedee.io</Anchor>
            </Row>
            <Row>
              <IconPhone /> <Anchor href="tel:924 942 123">680 18 32 14</Anchor>
            </Row>
            <Row>
              <IconClock /> <Anchor href="/">Lunes - Viernes 08:00 - 18:00</Anchor>
            </Row>
          </Section>
          <Section>
            <Title>Busca por ciudades</Title>
            {locations.list.map((location) => (
              <Link key={location.id} to={`/search?location=${location.slug}`}>
                {location.name}
              </Link>
            ))}
          </Section>
          <Section>
            <Title>Espacios</Title>
            {spaceCategories.map((category) => (
              <Link key={category.id} to={`/search?spaceCategory=${category.slug}`}>
                {category.name}
              </Link>
            ))}
          </Section>
          <Section>
            <Title>Enlaces de interés</Title>
            <Link to="/que-es-sedee">Que es Sedee</Link>
            <Link to="/how-sedee-works">¿Cómo funciona?</Link>
            <Link to="/general-terms">Términos generales</Link>
            <Link to="/cancellation-policies">Política de cancelación</Link>
            <Button type="button">
              <Link to="/send-space" target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                ¿Tienes un espacio? ÚNETE
              </Link>
            </Button>
          </Section>
        </Wrapper>
        <Line />
        <LegalSection>
          <LegalLink to="/">Copyright © 2024 - Sedee</LegalLink>
          <LastLinks>
            <LegalLink to="/terms-and-conditions">
              Términos y condiciones
            </LegalLink>
            <LegalLink to="/privacy">Política de privacidad</LegalLink>
            <LegalLink to="/cookies">Política de cookies</LegalLink>
            <LegalLink to="/payment-options">Formas de pago</LegalLink>
          </LastLinks>
        </LegalSection>
      </Content>
    </Container>
  );
};

export default memo(Footer);
