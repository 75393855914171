import React, { useEffect } from 'react';
import {
  BoxLoader,
  DescriptionLoader,
  SpaceLoader,
  TextLoader,
  TitleLoader,
} from 'components/Loaders';
import EmptyImage from 'assets/images/Card/emptyCards.svg';
import Gallery from 'components/Gallery';
import Markdown from 'components/Markdown';
import { Label } from 'components/Selector';
import VirtualTour from 'components/VirtualTour';
import {range} from 'lodash';
import { ENUM_BOOKING_STATE } from 'graphql/generated/globalTypes';
import {
  Address,
  BigImage,
  BookingButtonWrapper,
  BookingCard,
  Button,
  ButtonBack,
  CardsContainer,
  Carousel,
  Characteristic,
  Container,
  Content,
  GalleryButton,
  GalleryGrid,
  Grid,
  IconCheck,
  IconPicture,
  IconTour,
  InfoSection,
  Link,
  LinkIcon,
  LinkTextUppercase,
  Location,
  Map,
  MapContainer,
  Marker,
  MaxWidth,
  Row,
  RowContainer,
  RowSubtitle,
  RowText,
  RowTextContainer,
  Separator,
  Service,
  ServiceWrapper,
  SmallGrid,
  SmallImage,
  SpaceBetween,
  SpaceCard,
  Text,
  TextBold,
  Title,
  TourButton,
  Web,
  Clickable,
  Categories, FinalRow, BothContainers, MobileImageSeparator, SeparatorMobile,
} from './styles';
import useConnect from './connect';

const Spaces = () => {
  const {
    availability,
    isLoading,
    space,
    spaces,
    from,
    setFrom,
    to,
    setTo,
    // isLiked,
    // setIsLiked,
    handleGoBack,
    handleOpenTour,
    handleCloseTour,
    openTour,
    handleOpenGallery,
    handleCloseGallery,
    openGallery,
    isMobile,
    handleSubmitBooking,
    me,
    prices,
  } = useConnect();

  useEffect(() => {
    // @ts-ignore
    if (window.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({
        event: 'visita_detalles_de_evento',
      });
    }
  }, []);

  return (
      <BothContainers>
        <Container>
          <Content>
            {!isLoading && isMobile && (
                <>
                  <Carousel
                      images={space[0]?.images}
                      handleOpenGallery={handleOpenGallery}
                      hasOpenButton={space[0]?.images.length !== 0}
                  />
                  {space[0]?.images.length !== 0 && (
                      <Gallery
                          images={space[0]?.images}
                          open={openGallery}
                          handleClose={handleCloseGallery}
                      />
                  )}
                </>
            )}
            <Row>
              <ButtonBack
                  type="button"
                  onClick={handleGoBack}
                  $isWhite={space[0]?.images.length !== 0}
              />
              <SpaceBetween>
                {isLoading ? (
                    <InfoSection>
                      <Title>
                        <TitleLoader/>
                      </Title>
                      <Address>
                        <Location>
                          <TextLoader/>
                        </Location>
                        <Web to="">
                          <TextLoader/>
                        </Web>
                      </Address>
                    </InfoSection>
                ) : (
                    <InfoSection>
                      <MobileImageSeparator/>
                      <Title>{space[0]?.name}</Title>
                      <Address>
                        <Location>{space[0]?.fullLocation}</Location>
                      </Address>
                    </InfoSection>
                )}

                {
                  // <Options>
                  // <Share>Compartir</Share>
                  // <IconShare />
                  // <LikeButton
                  // variant="black"
                  // isLiked={isLiked}
                  // disabled={isLoading}
                  // onClick={() => setIsLiked(!isLiked)}
                  // />
                  // </Options>
                }
              </SpaceBetween>
            </Row>

            {isLoading && (
                <GalleryGrid>
                  <BigImage>
                    <BoxLoader/>
                  </BigImage>
                  <SmallGrid>
                    <SmallImage>
                      <BoxLoader/>
                    </SmallImage>
                    <SmallImage>
                      <BoxLoader/>
                    </SmallImage>
                    <SmallImage>
                      <BoxLoader/>
                    </SmallImage>
                    <SmallImage>
                      <BoxLoader/>
                    </SmallImage>
                  </SmallGrid>
                </GalleryGrid>
            )}

            {!isLoading && !isMobile && (
                <GalleryGrid>
                  <BigImage>
                    <Clickable onClick={handleOpenGallery} style={ {'maxHeight': '25rem'} }>
                      {
                        space[0]?.images[0]?.src === undefined ? (
                            <img src={EmptyImage} alt="titles" style={ {'maxHeight': '25rem'} }/>

                        ) : (
                            <img src={process.env.REACT_APP_API_URL + space[0]?.images[0]?.src} alt="title" style={ {'maxHeight': '25rem'} }/>
                        )
                      }

                    </Clickable>
                  </BigImage>
                  <SmallGrid style={ {'maxHeight': '25rem'} }>
                    <SmallImage>
                      <Clickable onClick={handleOpenGallery} style={ {'maxHeight': '11.875rem'} }>
                        {
                          space[0]?.images[1]?.src === undefined ? (
                              <img src={EmptyImage} alt="titles" style={ {'maxHeight': '11.875rem'} }/>

                          ) : (
                              <img src={process.env.REACT_APP_API_URL + space[0]?.images[1]?.src} alt="title" style={ {'maxHeight': '11.875rem'} }/>
                          )
                        }
                      </Clickable>
                    </SmallImage>
                    <SmallImage style={ {'maxHeight': '11.875rem'} }>
                      <Clickable onClick={handleOpenGallery}>
                        {
                          space[0]?.images[2]?.src === undefined ? (
                              <img src={EmptyImage} alt="titles" style={ {'maxHeight': '11.875rem'} }/>

                          ) : (
                              <img src={process.env.REACT_APP_API_URL + space[0]?.images[2]?.src} alt="title" style={ {'maxHeight': '11.875rem'} }/>
                          )
                        }
                      </Clickable>
                    </SmallImage>
                    <SmallImage style={ {'maxHeight': '11.875rem'} }>
                      <Clickable onClick={handleOpenGallery}>
                        {
                          space[0]?.images[3]?.src === undefined ? (
                              <img src={EmptyImage} alt="titles" style={ {'maxHeight': '11.875rem'} }/>

                          ) : (
                              <img src={process.env.REACT_APP_API_URL + space[0]?.images[3]?.src} alt="title" style={ {'maxHeight': '11.875rem'} }/>
                          )
                        }
                      </Clickable>
                    </SmallImage>
                    <SmallImage style={ {'maxHeight': '11.875rem'} }>
                      <Clickable onClick={handleOpenGallery}>
                        {
                          space[0]?.images[4]?.src === undefined ? (
                              <img src={EmptyImage} alt="titles" style={ {'maxHeight': '11.875rem'} }/>

                          ) : (
                              <img src={process.env.REACT_APP_API_URL + space[0]?.images[4]?.src} alt="title" style={ {'maxHeight': '11.875rem'} }/>
                          )
                        }
                      </Clickable>
                    </SmallImage>
                  </SmallGrid>

                  {space[0]?.images.length !== 0 && (
                      <>
                        <GalleryButton
                            variant="outlined"
                            size="small"
                            leftIcon={<IconPicture/>}
                            onClick={handleOpenGallery}
                            disabled={isLoading}
                        >
                          Todas las fotos
                        </GalleryButton>

                        <Gallery
                            images={space[0]?.images}
                            open={openGallery}
                            handleClose={handleCloseGallery}
                        />
                      </>
                  )}
                </GalleryGrid>
            )}

            <Row>
              <Grid>
                <MaxWidth>
                  <>
                    <SeparatorMobile/>
                    {!isLoading && space[0] && space[0]?.virtualTour && (
                        <>
                          <RowSubtitle>Finca de recreo</RowSubtitle>
                          <TourButton
                              variant="outlined"
                              size="small"
                              leftIcon={<IconTour/>}
                              onClick={handleOpenTour}
                              disabled={isLoading}
                          >
                            TOUR VIRTUAL
                          </TourButton>

                          <VirtualTour
                              open={openTour}
                              handleClose={handleCloseTour}
                              virtualTour={space[0]?.virtualTour}
                          />
                        </>
                    )}

                    {!isLoading &&
                        space[0] &&
                        space[0]?.space_categories.length > 0 && (
                            <>
                              <RowSubtitle>Tipo de espacio</RowSubtitle>
                              <Categories>
                                {space[0]?.space_categories.map((item: any) => (
                                    <Label key={item.id}>{item.name}</Label>
                                ))}
                              </Categories>
                            </>
                        )}

                    <RowSubtitle>Descripción</RowSubtitle>
                    {isLoading ? (
                        <DescriptionLoader/>
                    ) : (
                        <Markdown md={space[0]?.description}/>
                    )}
                    {space && space[0]?.extraInformation && (
                        <RowSubtitle id="price-and-conditions">
                          Precio y condiciones
                        </RowSubtitle>
                    )}
                    {isLoading ? (
                        <DescriptionLoader/>
                    ) : (
                        space &&
                        space[0]?.extraInformation && (
                            <Markdown md={space[0]?.extraInformation}/>
                        )
                    )}
                  </>
                  {false && !isLoading && (
                      <>
                        <Separator/>
                        <RowSubtitle>Servicios</RowSubtitle>
                        <ServiceWrapper>
                          {space[0]?.cateringExclusivity && (
                              <Service>
                                <IconCheck/> Exlusividad de catering
                              </Service>
                          )}
                          {space[0]?.audiovisualMedia && (
                              <Service>
                                <IconCheck/> Medios audiovisuales
                              </Service>
                          )}
                          {space[0]?.festivals && (
                              <Service>
                                <IconCheck/> Festivales
                              </Service>
                          )}
                          {space[0]?.meetings && (
                              <Service>
                                <IconCheck/> Mettings
                              </Service>
                          )}
                        </ServiceWrapper>
                        <Separator/>
                      </>
                  )}

                  {false && !isLoading && (
                      <>
                        <RowSubtitle>Características</RowSubtitle>
                        <ServiceWrapper>
                          <Characteristic>
                            <TextBold>Plazas de parking:</TextBold>
                            <Text>240</Text>
                          </Characteristic>
                          <Characteristic>
                            <TextBold>Número de salones:</TextBold>
                            <Text>2</Text>
                          </Characteristic>
                        </ServiceWrapper>
                        <Separator/>
                      </>
                  )}
                </MaxWidth>

                {!isLoading && availability && (
                    <BookingCard
                        from={from}
                        to={to}
                        fromHandleChange={setFrom}
                        toHandleChange={setTo}
                        availability={availability}
                        handleSubmit={handleSubmitBooking}
                        prices={prices}
                        values={{
                          total: availability.totalNumber,
                          startDate: from,
                          endDate: to,
                          space: space[0]?.id,
                          user: me?.id,
                          state: ENUM_BOOKING_STATE.pre_reserva_solicitada,
                        }}
                        showPriceAndConditions={!!space[0]?.extraInformation}
                    />
                )}
              </Grid>
            </Row>

            {isLoading ? (
                <FinalRow>
                  <RowSubtitle>Ubicación</RowSubtitle>
                  <MapContainer>
                    <BoxLoader/>
                  </MapContainer>
                  <TextBold>
                    <TextLoader/>
                  </TextBold>
                  <DescriptionLoader/>
                  <Separator/>
                </FinalRow>
            ) : (
                space[0]?.latitude &&
                space[0]?.longitude && (
                    <FinalRow>
                      <RowSubtitle>Ubicación</RowSubtitle>

                      <MapContainer>
                        <Map
                            latitude={space[0]?.latitude}
                            longitude={space[0]?.longitude}
                        >
                          <Marker
                              latitude={space[0]?.latitude}
                              longitude={space[0]?.longitude}
                          />
                        </Map>
                      </MapContainer>
                      <RowText>
                        <TextBold>{space[0]?.address}</TextBold>
                      </RowText>

                      <RowText>
                        <Web
                            to={{
                              pathname: space[0]?.web as string,
                            }}
                            target="_blank"
                        >
                          {space[0]?.web}
                        </Web>
                      </RowText>
                      <Separator/>
                    </FinalRow>
                )
            )}

            <RowContainer>
              <Row>
                <RowTextContainer>
                  <RowSubtitle>Espacios recomendados</RowSubtitle>
                  <Link to="/search">
                    <LinkIcon/>
                    <LinkTextUppercase>Ver todos</LinkTextUppercase>
                  </Link>
                </RowTextContainer>
                {isLoading ? (
                    <CardsContainer>
                      {range(4).map((item) => (
                          <SpaceLoader key={item}/>
                      ))}
                    </CardsContainer>
                ) : (
                    <CardsContainer>
                      {spaces &&
                          spaces.slice(0, 4).map((relationSpace: any) => (
                              <SpaceCard
                                  linkProps={{
                                    to: `/spaces/${relationSpace.slug}`,
                                  }}
                                  images={relationSpace.images}
                                  key={relationSpace.id}
                                  title={relationSpace.name}
                                  location={relationSpace.fullLocation}
                                  maxCapacity={relationSpace.capacity}
                                  price={relationSpace.price}
                                  canBeBookedInstantly={relationSpace.canInstantBooking}
                              />
                          ))}
                    </CardsContainer>
                )}
              </Row>
            </RowContainer>
          </Content>
        </Container>
        {availability?.available &&
            (
                <BookingButtonWrapper>
                  <Button className={availability?.canInstantBooking ? 'instant-book-btn' : 'check-price-btn'} fullWidth onClick={() => handleSubmitBooking({
                    total: availability?.totalNumber,
                    startDate: from,
                    endDate: to,
                    space: space[0]?.id,
                    user: me?.id,
                    state: ENUM_BOOKING_STATE.pre_reserva_solicitada,
                  })}>
                    {availability?.canInstantBooking
                        ? 'Reserva Inmediata'
                        : 'Consulta precio y disponibilidad'}
                  </Button>
                </BookingButtonWrapper>
            )
        }

      </BothContainers>

  );
};

export default Spaces;
