import { AvailabilitySpaces_availabilitySpaces } from 'graphql/generated/AvailabilitySpaces';
import { Spaces_spaces } from 'graphql/generated/Spaces';
import formatPriceNumber from 'utils/formatPrice';
import notEmpty from 'utils/notEmpty';
import { normalizeImage } from './global';

type InputSpace = Spaces_spaces;
type InputCustomSpace = AvailabilitySpaces_availabilitySpaces;
type Input = {
  id: string;
  slug: string;
  name: string;
};

// const getCoordinates = (url: string, type: 'lat' | 'long') => {
//   /* eslint-disable no-useless-escape */
//   const fragments = /\/\@(.*),(.*),/.exec(url);
//   const lat = fragments && Number(fragments[1]);
//   const long = fragments && Number(fragments[2]);
//
//   if (lat && type === 'lat') return lat;
//   if (long && type === 'long') return long;
//
//   return undefined;
// };

const normalizeItem = (input: Input) => {
  return {
    id: input.id,
    slug: input.slug,
    name: input.name,
  };
};

type FullLocation = {
  location: InputSpace['location'];
  town: InputSpace['town'];
};
const normalizeFullLocation = ({ location, town }: FullLocation) => {
  if (!location) {
    return '';
  }
  const hasLocation = location.name;
  const hasTown = town;
  const locationEqTown =
    hasLocation &&
    hasTown &&
    location.name.toLocaleLowerCase() === (town || '').toLocaleLowerCase();

  if (locationEqTown || !hasTown) {
    return location.name;
  }

  return `${location.name} - ${town}`;
};

export const normalizeSpace = (input: InputSpace) => {
  console.log(input.location)
  return {
    id: input.id,
    slug: input.slug,
    name: input.name || `Espacio ${input.id}`,
    description: input.DescriptionExtended || undefined,
    extraInformation: input.extraInformation || undefined,
    capacity: (input.capacity as number) || 0,
    numberOfRooms: input.numberOfRooms || undefined,
    parkingSpaces: input.parkingSpaces || undefined,
    cateringExclusivity: input.cateringExclusivity || undefined,
    audiovisualMedia: input.audiovisualMedia || undefined,
    festivals: input.festivals || undefined,
    meetings: input.meetings || undefined,
    openSpace: input.openSpace || undefined,
    style: input.style || undefined,
    music: input.music || undefined,
    airConditioner: input.airConditioner || undefined,
    wardrobe: input.wardrobe || undefined,
    location: input.location ? input.location : undefined,
    town: input.town || undefined,
    fullLocation: normalizeFullLocation({
      location: input.location[0],
      town: input.town,
    }),
    instantBooking: input.instantBooking || undefined,
    web: input.web || undefined,
    address: input.address || undefined,
    canInstantBooking: input.canInstantBooking,
    space_categories: (input?.space_categories || [])
      .filter(notEmpty)
      .map(normalizeItem),
    price: input.price,
    virtualTour: input.tourVirtual || undefined,
    images: (input.images || []).filter(notEmpty).map(normalizeImage),
    latitude: input.lat ? parseFloat(input.lat) : undefined,
    longitude: input.long ? parseFloat(input.long) : undefined,
  };
};

export const normalizeCustomSpace = (input: InputCustomSpace) => {
  return {
    id: input.id,
    slug: input.slug,
    name: input.name || `Espacio ${input.id}`,
    description: input.DescriptionExtended || undefined,
    extraInformation: input.extraInformation || undefined,
    capacity: (input.capacity as number) || 0,
    numberOfRooms: input.numberOfRooms || undefined,
    parkingSpaces: input.parkingSpaces || undefined,
    cateringExclusivity: input.cateringExclusivity || undefined,
    audiovisualMedia: input.audiovisualMedia || undefined,
    festivals: input.festivals || undefined,
    meetings: input.meetings || undefined,
    openSpace: input.openSpace || undefined,
    style: input.style || undefined,
    music: input.music || undefined,
    airConditioner: input.airConditioner || undefined,
    wardrobe: input.wardrobe || undefined,
    location: input.location ? normalizeItem(input.location) : undefined,
    town: input.town || undefined,
    fullLocation: normalizeFullLocation({
      location: input.location,
      town: input.town,
    }),
    instantBooking: input.instantBooking || undefined,
    web: input.web || undefined,
    address: input.address || undefined,
    price: formatPriceNumber(input.price || 0),
    canInstantBooking: input.canInstantBooking,
    space_categories: (input?.space_categories || [])
      .filter(notEmpty)
      .map(normalizeItem),
    virtualTour: input.tourVirtual || undefined,
    images: (input.images || []).filter(notEmpty).map(normalizeImage),
    latitude: input.lat ? parseFloat(input.lat) : undefined,
    longitude: input.long ? parseFloat(input.long) : undefined,
  };
};

export type Space = ReturnType<typeof normalizeSpace>;

export type CustomSpace = ReturnType<typeof normalizeCustomSpace>;
