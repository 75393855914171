export type SelectOption = {
  id: string;
  value: string;
  label: string;
};

export type Option = { label: string; value: string; disabled?: boolean };
export type Options = Option[];
export type SelectType = { label?: string; value: unknown };

export type ImageInput = {
  url: string;
  name: string;
};

export const normalizeImage = (input: any) => {
  return {
    src: input.src ?? input.url,
    alt: input.src ?? input.name,
  };
};

export const normalizeFile = (input: ImageInput) => {
  return {
    url: input.url,
    name: input.name,
  };
};

export type Image = ReturnType<typeof normalizeImage>;
