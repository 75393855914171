/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
    ButtonBack,
    Container, Content, InfoLi, InfoUl, InsideInfoContainer,
    Title,
} from './styles';
import {Header, InfoContainer, InfoText, InfoTitle} from "../Cookies/styles";
import useConnect from '../CancellationOptions/connect';


const Privacy = () => {
    const { handleGoBack } = useConnect();

    return (
        <Container>
            <Header>
            <ButtonBack onClick={handleGoBack} />
                <Title>POLÍTICAS DE PRIVACIDAD</Title>
            </Header>
            <Content>
                <InfoContainer>
                    <InfoTitle>
                        Aviso legal
                    </InfoTitle>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>
                        1.- Derecho de la información
                    </InfoTitle>
                    <InfoText>
                        Le informamos de que este Sitio Web es titularidad de Selección de Espacios para eventos S.L.,
                        (en adelante, SEDEE), con C.I.F. B-90371220 y domicilio social en Calle Almutamit 6, local 101
                        del Edificio vega 1, situado en la localidad de Camas - Sevilla.
                    </InfoText>
                    <InfoText>
                        El acceso y/o o uso al Sitio Web le atribuye la condición de usuario, y acepta, desde dicho
                        acceso y/o uso, el presente Aviso Legal.
                    </InfoText>
                    <InfoText>
                        El usuario (en adelante, el "Usuario") puede ponerse en contacto con SEDEE a través de la
                        siguiente dirección de correo electrónico: info@sedee.io
                    </InfoText>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>
                        2.- Uso del Sitio Web
                    </InfoTitle>
                    <InfoText>
                        El Usuario asume la responsabilidad del uso del Sitio Web. El Sitio Web puede proporcionar
                        acceso a multitud de textos, gráficos, dibujos, diseños, fotografías, contenidos multimedia, e
                        informaciones (en adelante, "Contenidos") pertenecientes a SEDEE o a terceros a los que el
                        Usuario puede tener acceso.
                    </InfoText>
                    <InfoText>
                        El Usuario se compromete a hacer un uso adecuado de los Contenidos y Servicios ofrecidos a
                        través del Sitio Web y con carácter enunciativo pero no limitativo, a no emplearlos para (A)
                        incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público; (B)
                        provocar daños en los sistemas físicos y lógicos del Sitio Web de SEDEE, de sus proveedores o de
                        terceras personas, (C) introducir o difundir en la red virus informáticos o cualesquiera otros
                        sistemas físicos o lógicos que sean susceptibles de provocar los daños anteriormente
                        mencionados, (D) intentar acceder, utilizar y/o manipular los datos de SEDEE, terceros
                        proveedores y otros Usuarios; (E) reproducir o copiar, distribuir, permitir el acceso del
                        público a través de cualquier modalidad de comunicación pública, transformar o modificar los
                        Contenidos, a menos que se cuente con la autorización de SEDEE; (F) suprimir, ocultar o
                        manipular los Contenidos sujetos a derechos de propiedad intelectual o industrial y demás datos
                        identificativos de dichos derechos de SEDEE o de terceros incorporados a los Contenidos, así
                        como los dispositivos técnicos de protección o cualesquiera mecanismos de información que puedan
                        insertarse en los Contenidos.
                    </InfoText>
                    <InfoText>
                        SEDEE tendrá derecho a investigar y denunciar cualquiera de las conductas mencionadas de acuerdo
                        con la Ley, así como a colaborar con las autoridades en la investigación de dichas actuaciones.
                    </InfoText>
                    <InfoText>
                        SEDEE podrá suspender temporalmente y sin previo aviso, la accesibilidad al Sitio Web con motivo
                        de operaciones de mantenimiento, reparación, actualización o mejora. No obstante, siempre que
                        las circunstancias lo permitan, SEDEE comunicará al Usuario, con antelación suficiente, la fecha
                        prevista para la suspensión de los Servicios. SEDEE no se responsabiliza del uso que los
                        Usuarios puedan hacer de los Contenidos incluidos en el Sitio Web.
                    </InfoText>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>
                        3.- Propiedad Intelectual
                    </InfoTitle>
                    <InfoText>
                        Todos los derechos de propiedad intelectual del Contenido de este Sitio Web y su diseño gráfico
                        son propiedad exclusiva de SEDEE, por lo que es SEDEE a quien le corresponde el ejercicio
                        exclusivo de los derechos de explotación de los mismos.
                    </InfoText>
                    <InfoText>
                        Por ello y en virtud de lo dispuesto en el Real Decreto Legislativo 1/1996, de 12 de Abril, por
                        el que se aprueba el Texto Refundido de la Ley de Propiedad Intelectual, así como en la Ley
                        17/2001, de 7 de diciembre, de Marcas y la legislación complementaria en materia de propiedad
                        intelectual e industrial, queda prohibida la reproducción, transmisión, adaptación, traducción,
                        distribución, comunicación pública, incluida su modalidad de puesta a disposición, o cualquier
                        otra explotación y/o modificación, total o parcial, sin previa autorización expresa de SEDEE, de
                        cualquier Contenido incluido en el Sitio Web.
                    </InfoText>
                    <InfoText>
                        SEDEE no concede ninguna licencia o autorización de uso de ninguna clase sobre sus derechos de
                        propiedad intelectual e industrial o sobre cualquier otra propiedad o derecho relacionado con el
                        Sitio Web, los Servicios o los Contenidos del mismo.
                    </InfoText>
                    <InfoText>
                        La legitimidad de los derechos de propiedad intelectual o industrial correspondientes a los
                        Contenidos aportados por los Usuarios es responsabilidad exclusiva de los mismos, por lo que el
                        Usuario mantendrá indemne a SEDEE de cualquier reclamación de terceros derivada de la
                        utilización ilícita de Contenidos en el Sitio Web.
                    </InfoText>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>
                        4.- Responsabilidad y Garantías
                    </InfoTitle>
                    <InfoText>
                        SEDEE declara que ha adoptado las medidas necesarias que, dentro de sus posibilidades y el
                        estado de la tecnología, permitan el correcto funcionamiento de su Sitio Web, así como la
                        ausencia de virus y componentes dañinos. Sin embargo, SEDEE no puede hacerse responsable de: (1)
                        la continuidad y disponibilidad de los Contenidos y Servicios; (2) la ausencia de errores en
                        dichos Contenidos ni la corrección de cualquier defecto que pudiera ocurrir; (3) la ausencia de
                        virus y/o demás componentes dañinos; (4) los daños o perjuicios que cause cualquier persona que
                        vulnere los sistemas de seguridad de SEDEE.
                    </InfoText>
                    <InfoText>
                        SEDEE no asume ninguna responsabilidad sobre los enlaces a otras páginas web que se encuentren
                        en el Sitio Web, pudiendo dirigir al Usuario a otros sitios web sobre los que SEDEE no tiene
                        ningún tipo de control, por lo que el Usuario accede bajo su exclusiva responsabilidad al
                        Contenido y en las condiciones de uso que rijan en los mismos.
                    </InfoText>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>
                        5.- Duración y modificación
                    </InfoTitle>
                    <InfoText>
                        El presente Aviso Legal estará vigente con carácter indefinido, pudiendo SEDEE efectuar cambios
                        sobre las condiciones especificadas en el mismo, y que entrarán en vigor desde el momento de su
                        publicación.
                    </InfoText>
                    <InfoText>
                        SEDEE podrá suprimir, añadir o cambiar tanto los Contenidos como los Servicios que presta, así
                        como también la forma en la que los mismos aparezcan localizados o presentados. Se entienden
                        como vigentes, las condiciones que estén publicadas en el momento en el que el Usuario acceda al
                        Sitio Web de SEDEE.
                    </InfoText>
                    <InfoText>
                        El acceso y/o el uso del Sitio Web se entenderá como una aceptación por parte del Usuario del presente Aviso Legal y sus condiciones y, en su caso, los cambios efectuados en las mismas.
                    </InfoText>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>
                        Política de privacidad
                    </InfoTitle>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>1.- ¿Quién es el responsable del tratamiento de sus datos?</InfoTitle>
                    <InfoText>
                        Le informamos que la titularidad del dominio de nuestra web www.sedee.io (en adelante el "Sitio Web"), es de SEDEE.
                    </InfoText>
                    <InfoText>
                        El acceso y/o uso al Sitio Web le atribuye la condición de Usuario (en adelante, el "Usuario"), e implica la aceptación, desde dicho acceso y/o uso, de la presente Política de Privacidad.
                    </InfoText>
                    <InfoText>
                        El Usuario podrá contactar con SEDEE en la siguiente dirección de correo electrónico: info@sedee.io
                    </InfoText>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>
                        2.- ¿Por qué SEDEE está legitimado para llevar a cabo el tratamiento de datos?
                    </InfoTitle>
                    <InfoText>
                        SEDEE está legitimado para tratar sus datos para poder llevar a cabo la prestación de sus servicios, así como por el consentimiento que haya prestado en el momento del registro en el Sitio Web.
                    </InfoText>
                    <InfoText>
                        SEDEE se toma de forma seria la protección de su privacidad y de sus datos personales. Por lo tanto, su información personal se conserva de forma segura y se trata con el máximo cuidado.
                    </InfoText>
                    <InfoText>
                        La presente Política de Privacidad regula el acceso y el uso al servicio (en adelante, el "Servicio") que SEDEE pone a disposición del Usuario interesado en los servicios y Contenidos alojados en el Sitio Web.
                    </InfoText>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>
                        3.- ¿Cuáles son las finalidades del tratamiento de sus datos de carácter personal por parte de SEDEE?
                    </InfoTitle>
                    <InsideInfoContainer>
                        <InfoTitle>
                            3.a) Tratamiento de datos de carácter personal por parte de SEDEE
                        </InfoTitle>
                        <InfoText>
                            Sus datos de carácter personal recabados por SEDEE podrán ser utilizados para las siguientes finalidades:
                        </InfoText>
                        <InfoUl>
                            <InfoLi>(A) La prestación de los servicios ofrecidos en el Sitio Web.</InfoLi>
                            <InfoLi>(B) Enviar comunicaciones comerciales de SEDEE sobre sus servicios mediante carta, teléfono, correo electrónico, SMS/MMS, notificaciones push o por otros medios de comunicación electrónica equivalentes siempre y cuando el Usuario haya consentido el tratamiento de sus datos personales con esta finalidad.</InfoLi>
                        </InfoUl>
                        <InfoText>
                            Asimismo, el Usuario consiente el tratamiento de sus datos para la elaboración de perfiles, así como para la segmentación de sus datos. Los citados tratamientos pueden tener como finalidad tanto el análisis y realización de estadísticas para conocer el tráfico y utilización del Sitio Web por parte de los Usuarios, como la determinación de sus gustos y preferencias para remitirle información promocional acorde con sus intereses.
                        </InfoText>
                        <InfoTitle>
                            3.b) Remisión de comunicaciones comerciales por cuenta de terceros
                        </InfoTitle>
                        <InfoText>
                            SEDEE podrá tratar sus datos, siempre y cuando haya aceptado este tratamiento de datos personales para enviarle por carta, teléfono, correo electrónico, SMS/MMS, o por otros medios de comunicación electrónica equivalentes, comunicaciones comerciales o información de empresas relacionadas con los siguientes sectores:
                        </InfoText>
                        <InfoUl>
                            <InfoLi>
                                Marketing y seguros: entidades aseguradoras locales, eventos, grandes espacios.
                            </InfoLi>
                            <InfoLi>
                                Ocio: deportes, conciertos, coleccionismo, turismo, fotografía, pasatiempos, transporte, editorial, comunicación y entretenimiento.
                            </InfoLi>
                            <InfoLi>
                                Gran Consumo: restauración, imagen y sonido, complementos, hogar, mobiliario, inmobiliario, alimentación y bebidas, salud y belleza, cuidado personal, moda y complementos, decoración, textil.
                            </InfoLi>
                        </InfoUl>
                        <InfoText>
                            No obstante, el Usuario podrá revocar el consentimiento, en cada comunicado comercial o publicitario que se le haga llegar, y en cualquier momento, mediante notificación en la dirección de correo electrónico info@sedee.io
                        </InfoText>
                    </InsideInfoContainer>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>
                        4.- Veracidad de los datos facilitados por los Usuarios
                    </InfoTitle>
                    <InfoText>
                        El Usuario garantiza que los datos personales facilitados son veraces y se hace responsable de comunicar a SEDEE cualquier modificación de los mismos. El Usuario responderá, en cualquier caso, de la veracidad de los datos facilitados, reservándose SEDEE el derecho a excluir de los Servicios registrados a todo Usuario que haya facilitado datos falsos, sin perjuicio de las demás acciones que procedan en Derecho.
                    </InfoText>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>
                        5.- Conservación de los datos
                    </InfoTitle>
                    <InfoText>
                        Los datos personales proporcionados se conservarán por parte de SEDEE mientras el Usuario no manifieste su voluntad de darse de baja de los servicios, y ello con la finalidad de recibir información sobre productos de la empresa.
                    </InfoText>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>
                        6.- Derechos del Usuario en relación con sus datos
                    </InfoTitle>
                    <InfoText>
                        El Usuario tiene derecho a (1) acceder a sus datos personales, así como a (2) solicitar la rectificación de los datos inexactos o, en su caso, solicitar su (3) supresión, (4) solicitar la limitación del tratamiento de sus datos, (5) oponerse al tratamiento de sus datos y (6) solicitar su portabilidad. Además, el Usuario podrá ejercer su (7) derecho al olvido.
                    </InfoText>
                    <InfoText>
                        El Usuario puede ejercer todos estos derechos en la siguiente dirección de email info@sedee.io, indicando el motivo de su petición y aportando una copia de su D.N.I., con el fin de evitar que terceros tengan acceso a sus datos personales de forma indebida.
                    </InfoText>
                    <InfoText>
                        Sin perjuicio de cualquier otro recurso administrativo o acción judicial, el Usuario tendrá derecho a presentar una reclamación ante una Autoridad de Control, en particular en el Estado miembro en el que tenga su residencia habitual, lugar de trabajo o lugar de la supuesta infracción, en caso de que considere que el tratamiento de sus datos personales no es adecuado a la normativa, así como en el caso de no ver satisfecho el ejercicio de sus derechos. La autoridad de control ante la que se haya presentado la reclamación informará al reclamante sobre el curso y el resultado de la reclamación.
                    </InfoText>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>
                        7.- Seguridad de los datos
                    </InfoTitle>
                    <InfoText>
                        La protección de la privacidad y los datos personales de los Usuarios es muy importante para SEDEE. Por lo tanto, SEDEE hace todo lo que está en su mano para impedir que sus datos se utilicen de forma inadecuada, permitiendo el acceso a los mismo únicamente a personal autorizado.
                    </InfoText>
                    <InfoText>
                        SEDEE mantiene los niveles de seguridad de protección de datos personales conforme a la normativa aplicable y ha establecido todos los medios técnicos a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos que el Usuario facilite a través del Sitio Web, sin perjuicio de informarle de que las medidas de seguridad en Internet no son inexpugnables.
                    </InfoText>
                    <InfoText>
                        SEDEE se compromete a cumplir con el deber de secreto y confidencialidad respecto de los datos personales de acuerdo con la legislación aplicable, así como a conferirles un tratamiento seguro en las cesiones y transferencias internacionales de datos que, en su caso, puedan producirse.
                    </InfoText>
                    <InfoText>
                        En aquellos servicios de la web que precisen registrarse como Usuario, se deberá elegir una contraseña. El Usuario es el responsable de mantener la confidencialidad de esta contraseña, así como de todas las actividades que ocurran en la sesión iniciada con su nombre y contraseña. El Usuario se compromete a notificar a SEDEE a la mayor brevedad el uso no autorizado de su nombre de Usuario y/o contraseña o cualquier otro fallo en la seguridad. SEDEE no será responsable por los daños o pérdidas que se pudieran originar debido al no cumplimiento de esta obligación por parte del Usuario.
                    </InfoText>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>
                        8.- Cambios
                    </InfoTitle>
                    <InfoText>
                        SEDEE se reserva el derecho de revisar su Política de Privacidad en el momento que considere oportuno. Por esta razón, le rogamos que compruebe de forma regular esta Política de Privacidad para leer la versión más reciente de la misma. No obstante, cualquier cambio que se produzca en la presente Política de Privacidad se comunicará al Usuario.
                    </InfoText>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>
                        9.- Links a páginas web
                    </InfoTitle>
                    <InfoText>
                        El Sitio Web de SEDEE podría contener links a páginas web de compañías y entidades de terceros.
                    </InfoText>
                    <InfoText>
                        SEDEE no puede hacerse responsable de la forma en la que estas compañías tratan la protección de la privacidad y de los datos personales, por lo que le aconsejamos que lea detenidamente las declaraciones de Política de Privacidad de estas páginas web que no son propiedad de SEDEE con relación al uso, procesamiento y protección de datos personales. Las condiciones que ofrecen estas páginas web pueden no ser las mismas que las que ofrece SEDEE.
                    </InfoText>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>
                        10.- Preguntas
                    </InfoTitle>
                    <InfoText>
                        Si tienen alguna pregunta sobre esta Política de Privacidad o el tratamiento de sus datos rogamos que se ponga en contacto con SEDEE mediante correo electrónico dirigido a la siguiente dirección de correo electrónico: info@sedee.io
                    </InfoText>
                </InfoContainer>
                <InfoContainer>
                    <InfoTitle>
                        11.- Aceptación y Consentimiento
                    </InfoTitle>
                    <InfoText>
                        El Usuario declara haber sido informado de las condiciones sobre protección de datos personales, aceptando y consintiendo el tratamiento de los mismos por parte de SEDEE, en la forma y para las finalidades indicadas en la presente Política de Privacidad.
                    </InfoText>
                </InfoContainer>
            </Content>
        </Container>
    );
};

export default Privacy;
