import { useMediaQuery, from } from 'styles/media';
import useQuery from 'utils/useQueryParams';
import useAvailability from 'graphql/hooks/useAvailability';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { SelectType } from 'model/global';

const useConnect = () => {
  const ITEMS_PER_PAGE = 16;
  const isMobile = !useMediaQuery(from.tablet);
  const { query, setQuery, filterCount } = useQuery();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [start, setStart] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [filteredSpaces, setFilteredSpaces] = useState<any[]>([]);
  const {
    location,
    spaceCategory,
    minPrice,
    maxPrice,
    capacity,
    startDate,
    endDate,
    sort,
  } = query;

  const options = [
    { label: 'Ordenar por', value: 'default' },
    { label: 'Precio más alto', value: 'price:DESC' },
    { label: 'Precio más bajo', value: 'price:ASC' },
  ];

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const handleUpdatePage = useCallback(
      (newPage: number) => {
        setCurrentPage(newPage);
        setStart(newPage * ITEMS_PER_PAGE - ITEMS_PER_PAGE);
      },
      []
  );

  const handleOrderChange = useCallback(
      (event: ChangeEvent<SelectType>) => {
        const order = event.target.value as string;
        setQuery({ sort: order === 'default' ? undefined : order });
      },
      [setQuery]
  );

  const { loading, fetchAvailabilitySpaces, spaces } = useAvailability({
    canInstantBooking: false,
    take: 99999,
    location,
    startDate,
    endDate,
    sort: sort || undefined,
  });

  useEffect(() => {
    fetchAvailabilitySpaces();
  }, [location, startDate, endDate, fetchAvailabilitySpaces]);

  useEffect(() => {
    console.log(spaces)
    if (spaces) {
      const filtered = spaces.filter((space: any) => {
        const spacePrice = space.price;

        const isMinPriceValid = minPrice ? spacePrice >= minPrice : true;
        const isMaxPriceValid = maxPrice ? spacePrice <= maxPrice : true;

        const isSpaceCategoryValid = spaceCategory?.length
            ? space.space_categories && space.space_categories.some((category: any) => spaceCategory.includes(category.slug))
            : true;

        const isCapacityValid = capacity ? space.capacity >= capacity : true;

        return isMinPriceValid && isMaxPriceValid && isSpaceCategoryValid && isCapacityValid;
      });


      if (sort === 'price:ASC') {
        filtered.sort((a: any, b: any) => a.price - b.price);
      } else if (sort === 'price:DESC') {
        filtered.sort((a: any, b: any) => b.price - a.price);
      }

      setFilteredSpaces(filtered);
    }
  }, [spaces, minPrice, maxPrice, spaceCategory, capacity, sort]);

  const formatTitle = (title?: string | null) => {
    if (title) {
      const res = (title.charAt(0).toUpperCase() + title.slice(1))
          .split('-')
          .join(' ');
      return res;
    }
    return title;
  };

  const title =
      formatTitle(location) ||
      formatTitle(spaceCategory && spaceCategory[0]) ||
      'Espacios disponibles';

  return {
    isLoading: loading,
    title,
    isMobile,
    spaces: filteredSpaces,
    handleOrderChange,
    sort,
    currentPage,
    handleUpdatePage,
    ITEMS_PER_PAGE,
    open,
    handleOpenModal,
    handleCloseModal,
    start,
    filterCount,
    options,
  };
};

export default useConnect;
