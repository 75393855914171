import { useEffect, useState, useMemo, useCallback } from 'react';
import {normalizeSpace} from 'model/Space';

const useAvailability = (variables?: {
  page?: number;
  take?: number;
  sort?: string;
  location?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  spaceCategory?: (string | null)[] | null;
  minPrice?: number | null;
  maxPrice?: number | null;
  capacity?: number | null;
  canInstantBooking?: boolean | null;
  reducedMovility?: boolean | null;
  slug?: string | null; }) => {
  const [spaces, setSpaces] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const backendUrl = process.env.REACT_NESTJS_API_URL ?? 'https://api.sedee.io';

  const page = variables?.page || 1;
  const take = variables?.take || 4;
  const sort = variables?.sort;
  const location = variables?.location;
  const startDate = variables?.startDate;
  const endDate = variables?.endDate;
  const slug = variables?.slug;
  const canInstantBooking = variables?.canInstantBooking;

  const fetchAvailabilitySpaces = useCallback(async () => {
    try {
      setLoading(true);
      const locationQuery = location ? `&location=${location}` : '';
      const startDateQuery = startDate ? `&startDate=${startDate}` : '';
      const endDateQuery = endDate ? `&endDate=${endDate}` : '';
      const slugQuery = slug ? `&slug=${slug}` : '';
      const queryCanInstantBooking = canInstantBooking ? `&canInstantBooking=${canInstantBooking}` : '';
      const response = await fetch(
          `${backendUrl}/get-availability-spaces?page=${page}&take=${take}${locationQuery}${startDateQuery}${endDateQuery}${slugQuery}${queryCanInstantBooking}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'accept': '*/*',
            },
          }
      );
      const data = await response.json();
      const normalizedSpaces =data.data.map(
          (space: any) => {
            return normalizeSpace(space)
          }
      )
      setSpaces(normalizedSpaces);
    } catch (error) {
      setSpaces([]);
    } finally {
      setLoading(false);
    }
  }, [backendUrl, page, take, location, startDate, endDate, canInstantBooking, slug]);

  useEffect(() => {
    fetchAvailabilitySpaces();
  }, [fetchAvailabilitySpaces]);

  const sortedSpaces = useMemo(() => {
    if (sort === undefined) {
      return spaces.sort((value: any) => (value.canInstantBooking ? -1 : 1));
    }
    return spaces;
  }, [spaces, sort]);

  return {
    spaces: sortedSpaces,
    loading,
    fetchAvailabilitySpaces,
  };
};

export default useAvailability;
