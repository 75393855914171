import styled, {css} from 'styled-components';
import { from } from 'styles/media';
import DefaultMaxContent from "../../../components/MaxContent";
import { H4, H5, P } from "../../../components/Typography";
import DefaultButton from "../../../components/Button";
import DefaultButtonBack from "../../../components/ButtonBack";

/* eslint-disable import/prefer-default-export */

export const Container = styled(DefaultMaxContent)`
  padding: 2rem;
  
`;
export const InfoText = styled(P)`
  margin-top: 1rem;
  :first-child {
    margin-top: 0;
  }
`;
export const InfoTitle = styled(H5)<{ $isBlue?: boolean }>`
  color: ${({ theme }) => theme.colors.ebonyClay};
  ${({ $isBlue }) =>
    $isBlue &&
    css`
      color: ${({ theme }) => theme.colors.royalBlue};
    `}
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
  margin-bottom: 1.75rem;
`;

export const Content = styled.div`
  max-width: 100%;
`;

export const Button = styled(DefaultButton)`
  vertical-align: middle;
  width: 25%;
  border-radius: 0.3125rem;
  margin-bottom: 0.4rem;
  padding-bottom: 8px;
`;

export const Title = styled(H4)``;

export const ButtonBack = styled(DefaultButtonBack)`
  margin-right: 1.5rem;
  margin-top: 0.5rem;
`;

export const Section = styled.div`
  width: 100%;
  margin-top: 2.375rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-gap: 2rem;

  ${from.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }

  ${from.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export  const InfoUl = styled.ul`
  padding: 0 3rem;
`;

export const InfoLi = styled.li`
  list-style: circle;
  padding: 0.30rem;
`;

export const InsideInfoContainer = styled.div`
  padding: 1.5rem;
`;
