import React from 'react';
import {
    Container,
    Title,
    ButtonBack,
  Ol
} from './styles';
import {Header, InfoContainer, InfoText, InfoTitle} from "../Cookies/styles";
import {Strong} from "../../Shared/Modals/TransferModal/styles";
import {Li} from "../PaymentOptions/styles";
import useConnect from '../CancellationOptions/connect';


const WhatItsSedee = () => {
  const { handleGoBack } = useConnect();

    return (
        <Container>
            <Header>
            <ButtonBack onClick={handleGoBack} />
                <Title>Políticas de Cancelación para la Reserva de Espacios en Sedee</Title>
            </Header>
            <InfoContainer>
                <InfoText>
                  Para confirmar la reserva de un espacio en Sedee, es necesario realizar el pago
                  del 50% del importe total como depósito inicial. El saldo restante, equivalente al
                  otro 50% del importe total del evento, deberá ser abonado completando así el
                  100% del pago antes del evento según los términos acordados individualmente
                  con cada espacio, normalmente 15 días antes
                </InfoText>
                <InfoText>
                  Durante el proceso de contratación, se detallarán todas las condiciones
                  específicas relacionadas con cada espacio. Estas condiciones pueden incluir
                  políticas de cancelación únicas, requisitos adicionales de depósito, o
                  restricciones específicas de uso. Los clientes deben revisar y aceptar todas estas
                  condiciones específicas al realizar la reserva.
                </InfoText>
                <InfoTitle>
                  Política de Cancelación
                </InfoTitle>
                <InfoText>
                  Las circunstancias pueden cambiar y a veces puede ser necesario cancelar un
                  evento planificado. A continuación, se describen las políticas aplicables en caso
                  de cancelación:
                </InfoText>
                <Ol>
                  <Li><Strong>Cancelación por parte del cliente:</Strong> Si un cliente decide cancelar el evento, perderá el 50% del importe pagado en
                    concepto de reserva. Además, existe la posibilidad de modificar la fecha del
                    evento, sujeto a la disponibilidad y aceptación del espacio bajo sus condiciones
                    específicas.</Li>
                  <Li><Strong>Cancelación por parte del espacio:</Strong> Si el espacio necesita cancelar un evento debido a causas de fuerza mayor o
                    circunstancias inesperadas, se notificará al cliente lo antes posible. En este caso,
                    se procederá a la devolución del 100% de los pagos realizados hasta la fecha,
                    incluido el depósito inicial. También se ofrecerá la posibilidad de reprogramar el
                    evento, si el cliente lo desea y el espacio lo permite.
                  </Li>
                </Ol>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>
                  ¿Qué ocurre si no puedo viajar debido a circunstancias excepcionales en
                  mi lugar de origen?
                </InfoTitle>
                <InfoText>
                  En Sedee, comprendemos que pueden surgir circunstancias excepcionales que
                  estén fuera de su control y que afectan su capacidad para viajar. En tales casos,
                  le recomendamos seguir estos pasos:
                </InfoText>
                <Ol>
                  <Li><Strong>Revisar las Condiciones del Seguro:</Strong> Si ha adquirido un seguro para su
                    evento a través de nosotros o externamente, le aconsejamos revisar las
                    condiciones y coberturas específicas proporcionadas por su póliza. Los
                    seguros a menudo incluyen protección en casos de circunstancias
                    excepcionales, como cancelaciones por situaciones imprevistas en tu
                    lugar de origen.</Li>
                  <Li><Strong>Consultar las Condiciones del Establecimiento:</Strong> Si no ha adquirido un
                    seguro de viaje o si las circunstancias excepcionales no están cubiertas
                    por su póliza, le recomendamos ponerse en contacto directamente con el
                    establecimiento en el que realizó su reserva. Cada establecimiento puede
                    tener políticas de cancelación y modificación diferentes, por lo que es
                    importante conocer sus condiciones específicas y negociar posibles
                    soluciones directamente con ellos.</Li>
                </Ol>
                <InfoText>
                  En Sedee, estamos comprometidos a brindarle asistencia y apoyo en situaciones
                  imprevistas.
              </InfoText>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>
                  ¿Cómo se tramitan las devoluciones por cancelación dentro del tiempo
                  estipulado?
                </InfoTitle>
                <InfoText>
                  <Strong>Mismo método de pago:</Strong> Las devoluciones por cancelación se realizarán a través
                  del mismo método de pago que se utilizó para efectuar la reserva inicial. Esto
                  significa que si realizaste el pago con tarjeta de crédito, la devolución se
                  efectuará a esa misma tarjeta. Si optaste por una transferencia bancaria, el
                  reembolso se procesará mediante transferencia bancaria.
                </InfoText>
                <InfoText>
                  Puedes ponerse en contacto con el equipo de administración al correo 
                  <a href="mailto:administracion@sedee.io">administracion@sedee.io</a> y le guiarán de una forma sencilla.
                </InfoText>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>
                  ¿Cuánto tardaré en recibir el reembolso por la cancelación realizada?
                </InfoTitle>
                <InfoText>
                  El tiempo estimado para procesar los reembolsos por cancelación no superará
                  las 48 horas a partir de la recepción de los datos necesarios. Queremos
                  asegurarnos de que reciba su reembolso de manera rápida y sin demoras
                  innecesarias.
                </InfoText>
            </InfoContainer>
            <InfoContainer>
              <InfoTitle>
                ¿Han cambiado las características del evento, como el número de
                personas, las fechas o el formato, y el espacio ya no cumple con los
                requisitos necesarios? ¿Puedo cancelarlo?
              </InfoTitle>
              <InfoText>
                Sí, puede cancelarlo aceptando las condiciones de cancelación del espacio
                reservado.
              </InfoText>
            </InfoContainer>
            <InfoContainer>
              <InfoTitle>
                Modificaciones de la Política de Cancelación
              </InfoTitle>
              <InfoText>
                Sedee se reserva el derecho de modificar estas políticas de cancelación en
                cualquier momento. Cualquier cambio será comunicado a los usuarios con
                anticipación adecuada y será efectivo inmediatamente tras su publicación en
                nuestra plataforma.
              </InfoText>
            </InfoContainer>
        </Container>
    );
};

export default WhatItsSedee;
